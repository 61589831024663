import React, { useState, useContext, useEffect } from "react"
import styled from 'styled-components'
import LanguageContext from '../../context/languageContext'
import { Link } from 'gatsby'

import Scrollspy from 'react-scrollspy'
import media from '../../styles/media'
import { isMobile } from "react-device-detect"

const NavDiv = styled.div`
  color: white;

  a {
    color: white;
    mix-blend-mode: difference;
    text-decoration: none;
  }

  li {
    list-style: none;
    padding: 0;
  }

  .fourmenu {
    position: fixed;
    bottom: 7vh;
    padding: 0 0 0 var(--gutter-s);
    z-index: 9;
    color: white;
    text-transform: uppercase;
  }

  .menu {
    position: fixed;
    bottom: 7vh;
    padding: 0 0 0 var(--gutter-s);
    z-index: 9;

    ul {
      list-style: none;
      padding: 0;
      text-transform: uppercase;

      a {
        color: white;
        mix-blend-mode: difference;
        text-decoration: none;
      }

      li {
        width: fit-content;
        &::after {
          content: '';
          display: block;
          width: 0;
          height: 1px;
          background: white;
          transition: width .3s linear;
        }
        &.active {
          &::after {
            width: 100%;
          }
        }
        &:hover {
          &::after {
            width: 100%;
          }
        }
        &.active {
          &::after {
            width: 100%;
          }
        }
      }
    }

    &.desk {
      display: none;
    }

    &.mob {
      left: 20px;
      background: white;
      border-radius: 5px;
      padding: 12px;
      color: black;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      overflow: hidden;

      width: 72px;
      height: 10px;
      transition: all .5s linear;

      p {
        padding: 0;
        margin: 0;
      }

      button {
        background: transparent;
        border: none;
        outline: none;
      }

      .menuTop {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
        margin-top: -5px;

        .exit {
          display: none;
        }
      }

      .language {
        font-size: 10px;
        display: flex;
        button {
          font-size: 10px;
          line-height: 12px;
          text-transform: uppercase;
          padding: 0 5px 0 0;
          width: 55px;
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 10px;
            margin-top: -2px;
            margin-left: 5px;
            object-fit: cover;
            margin-bottom:0;
          }
        }
      }

      ul {
        li {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 5px;
        }
          margin-bottom: 30px;
      }


      &.open {
        width: 170px;
        height: 188px;

        .menuTop {
          justify-content: space-between;
          margin-top: 0;
          .exit {
            display: block;
          }
        }
      }
    }
  }
  
  ${media.tablet`
    mix-blend-mode: difference;

    .fourmenu {
      display: none;
    }

    .menu {
      bottom: 8vh;
      padding: 0 0 0 40px;

      &.desk {
        display: block;
        text-transform: uppercase;
        ul {
        margin-left: 0;
          li {
            margin-top: 8px;
            font-size: 14px;
          }
        }

        .language {
        display: flex;
        button {
          img {
            width: 20px;
            height: 14px;
            margin-left: 5px;
            margin-top: 0;
            object-fit: cover;
          }
        }
      }
      }
      &.mob {
        display: none;
      }
    }
  `}
`

const Nav = () => {
  const [open, setOpen] = useState(false)
  const [onfour, setOnfour] = useState(false)
  const language = useContext(LanguageContext);

  const url = typeof window !== 'undefined' ? window.location.href : '';

  useEffect(() => {
    if(url.includes('404')) {
      setOnfour(true)
    }
  }, [onfour])

  useEffect(() => {
    console.log(language);
  }, [language])

  const onHomeClick = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0)
    setOpen(false)
  }

  const onAboutClick = (e) => {
    e.preventDefault()
    const anchor = e.target.getAttribute('href').substr(1)
    window.scrollTo(0, document.getElementById(anchor).offsetTop * 1.75)
    setOpen(false)
  }

  const onProductClick = (e) => {
    e.preventDefault()
    const anchor = e.target.getAttribute('href').substr(1)
    window.scrollTo(0, document.getElementById(anchor).offsetTop * 1.75)
    setOpen(false)
  }

  const onContactClick = (e) => {
    e.preventDefault()
    const anchor = e.target.getAttribute('href').substr(1)
    window.scrollTo(0, document.getElementById(anchor).offsetTop * 1.75)
    setOpen(false)
  }

  const setEng = () => {
    language.updateLang('english')
    setOpen(false)
  }

  const setMan = () => {
    language.updateLang('mandarin')
    setOpen(false)
  }

  const setFrench = () => {
    language.updateLang('french')
    setOpen(false)
  }

  return (
    <NavDiv>
      <div className="menu desk">
        {onfour && (
          <li>
            <Link to="/">
              {language.language === 'english' && (
                <>
                  Home
                </>
              )}
              {language.language === 'mandarin' && (
                <>
                  家
                </>
              )}
              {language.language === 'french' && (
                <>
                  Domicile
                </>
              )}
            </Link>
          </li>
        )}
        {!onfour && (
          <Scrollspy items={['home', 'about', 'product', 'contact']} currentClassName="active">
            <li>
              <a href="#home" onClick={onHomeClick}>
                {language.language === 'english' && (
                  <>
                    Home
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    家
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    Domicile
                  </>
                )}
              </a>
            </li>
            <li>
              <a href="#about" onClick={onAboutClick}>
                {language.language === 'english' && (
                  <>
                    About <span href="#about">Us</span>
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    关于我们
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    À propos <span href="#about">de nous</span>
                  </>
                )}
              </a>
            </li>
            <li>
              <a href="#product" onClick={onProductClick}>
                {language.language === 'english' && (
                  <>
                    Our <span href="#product">Products</span>
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    我们的产品
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    Nos <span href="#product">produits</span>
                  </>
                )}
              </a>
            </li>
            <li>
              <a href="#contact" onClick={onContactClick}>
                {language.language === 'english' && (
                  <>
                    Contact <span href="#contact">Us</span>
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    联系我们
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    Nous <span href="#contact">contacter</span>
                  </>
                )}
              </a>
            </li>
          </Scrollspy>
        )}
      </div>

      {onfour && (
        <div className='fourmenu mob'>
          <li>
            <Link to="/">
              {language.language === 'english' && (
                <>
                  Home
                </>
              )}
              {language.language === 'mandarin' && (
                <>
                  家
                </>
              )}
              {language.language === 'french' && (
                <>
                  Domicile
                </>
              )}
            </Link>
          </li>
        </div>
      )}
      {!onfour && (
        <div className={open ? 'menu mob open' : 'menu mob closed'}>
          <div className="menuTop">
            <p className="menuButt" onClick={() => setOpen(true)} onKeyDown={() => setOpen(true)}> 
              {language.language === 'english' && (
                <span>
                  Menu
                </span>
              )}
              {language.language === 'mandarin' && (
                <p>
                  菜单
                </p>
              )}
              {language.language === 'french' && (
                <span>
                Menu
              </span>
              )}
            </p>
            <button className="exit" onClick={() => setOpen(false)}>&times;</button>
          </div>
          <ul>
            <li>
              <a href="#home" onClick={onHomeClick}>
                {language.language === 'english' && (
                  <>
                    Home
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    家
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    Domicile
                  </>
                )}
              </a>
            </li>
            <li>
              <a href="#about" onClick={onAboutClick}>
                {language.language === 'english' && (
                  <>
                    About <span href="#about">Us</span>
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    关于我们
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    À propos <span href="#about">de nous</span>
                  </>
                )}
              </a>
            </li>
            <li>
              <a href="#product" onClick={onProductClick}>
                {language.language === 'english' && (
                  <>
                    Our <span href="#product">Products</span>
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    我们的产品
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    Nos <span href="#product">produits</span>
                  </>
                )}
              </a>
            </li>
            <li>
              <a href="#contact" onClick={onContactClick}>
                {language.language === 'english' && (
                  <>
                    Contact <span href="#contact">Us</span>
                  </>
                )}
                {language.language === 'mandarin' && (
                  <>
                    联系我们
                  </>
                )}
                {language.language === 'french' && (
                  <>
                    Nous <span href="#contact">contacter</span>
                  </>
                )}
              </a>
            </li>
          </ul>
          <div className="language">
            <button onClick={setEng} className={language.language === 'english' ? 'active' : ''}>En <img src="https://camo.githubusercontent.com/0f213bb0d662e12cc28d9da053251a1c76d688d69100a70c2a4d69bbf842e625/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f612f61652f466c61675f6f665f7468655f556e697465645f4b696e67646f6d2e737667" alt="flag" /></button> &nbsp;&nbsp;
            <button onClick={setMan} className={language.language === 'mandarin' ? 'active' : ''}>Ch <img src="https://camo.githubusercontent.com/36b999b8d62e3dad262dec7ba75ef4cad465cec20fc3c75eaab329a6a63bf4ee/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f662f66612f466c61675f6f665f7468655f50656f706c65253237735f52657075626c69635f6f665f4368696e612e737667" alt="flag" /></button> &nbsp;&nbsp;
            <button onClick={setFrench} className={language.language === 'french' ? 'active' : ''}>Fr <img src="https://camo.githubusercontent.com/f4a3d88a2f40abc7a7ff08a39ae6ca21366250a5d603c131f6ad577239d0c119/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f632f63332f466c61675f6f665f4672616e63652e737667" alt="flag" /></button>
          </div>
        </div>
      )}
    </NavDiv>
  );
}
export default Nav;